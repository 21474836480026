import { Center, Flex, Text } from "@chakra-ui/layout";
import { formatMoney } from "core/helpers/number";
import colors from "core/resources/theme/colors";
import ContentItem from "../ContentItem";
import moment from "moment";
import { IngressoItemDetalhado } from "core/features/ingresso/typings";
import Button from "ui/components/Button/Button";
import { IoTicketSharp } from "react-icons/io5";
import ModalAlterarData from "./ModalAlterarData";
import { useRef, useState } from "react";
import { BiPrinter } from "react-icons/bi";
import { useReactToPrint } from "react-to-print";
import ImpressaoIngresso from "../ImpressaoIngresso";
import { useIngressoItem } from "core/features/ingresso/hooks/useIngressoItem";
import { QRCodeSVG } from "qrcode.react";

interface CardIngressoProps {
  data: IngressoItemDetalhado;
  idVenda: number;
  eventoId: number;
}

export default function CardIngresso({
  data,
  idVenda,
  eventoId,
}: CardIngressoProps) {
  const [openModal, setOpenModal] = useState(false);
  const { data: ingresso } = useIngressoItem(data.id, idVenda);
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <ImpressaoIngresso data={ingresso ?? {}} ref={componentRef} />
      <Flex
        flexDir="column"
        gap={2}
        bg={colors.gray200}
        p={4}
        pb={16}
        w="100%"
        rounded={10}
      >
        <Flex flexWrap={"wrap"} justify={"space-between"}>
          <Text color={colors.blue} fontSize="20px">
            {data.opcaoNome}
          </Text>

          {data?.flgPodeMarcar && (
            <Button
              onClick={() => setOpenModal(true)}
              bg={colors.red}
              title={"Preencher"}
              icon={<IoTicketSharp />}
            />
          )}
          {data?.flgPodeImprimir && (
            <Button
              onClick={handlePrint}
              bg={colors.blue300}
              title={""}
              icon={<BiPrinter />}
            />
          )}
        </Flex>
        <ContentItem titulo="SETOR" descricao={ingresso?.setorNome ?? "--"} />
        <ContentItem
          titulo="VALOR TOTAL"
          descricao={formatMoney(ingresso?.valorTotal)}
        />
        <ContentItem titulo="STATUS" descricao={ingresso?.statusNome} />
        {ingresso?.utilizadorNome && (
          <ContentItem
            titulo="NOME DO UTILIZADOR:"
            descricao={ingresso?.utilizadorNome}
          />
        )}
        {ingresso?.utilizadorDocumento && (
          <ContentItem
            titulo="DOCUMENTO UTILIZADOR:"
            descricao={ingresso?.utilizadorDocumento}
          />
        )}
        {ingresso?.utilizadorEmail && (
          <ContentItem
            titulo="EMAIL DO UTILIZADOR:"
            descricao={ingresso?.utilizadorEmail}
          />
        )}
        {ingresso?.dataPrevistaUtilizacao && (
          <ContentItem
            titulo="DATA PREVISTA UTILIZAÇÃO:"
            descricao={moment(ingresso?.dataPrevistaUtilizacao).format(
              "DD/MM/YYYY"
            )}
          />
        )}
        {ingresso?.dataUtilizacao && (
          <ContentItem
            titulo="DATA UTILIZAÇÃO:"
            descricao={moment(ingresso.dataUtilizacao ?? "--").format(
              "DD/MM/YYYY"
            )}
          />
        )}
        {ingresso?.chave && ingresso.flgMarcado && (
          <Center>
            <ContentItem
              titulo=""
              descricao={<QRCodeSVG size={200} value={ingresso?.chave ?? ""} />}
            />
          </Center>
        )}
        <ModalAlterarData
          idVenda={idVenda}
          eventoId={eventoId}
          idIngresso={data.id}
          visible={openModal}
          onClose={() => setOpenModal(false)}
        />
      </Flex>
    </>
  );
}
